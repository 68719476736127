/*global __lodash__, Component */
class orderDetailComponent extends Component {

    static name() {
        return "orderDetailComponent";
    }

    static componentName() {
        return "orderDetailComponent";
    }

    getProps() {
        return  ['SOSerNr',"goBackView"];
    }

    data() {
        return {
            viewMode:true,
            backup:null,
            refreshFlag:1,
            isOrderLoading:true,
            libertorByOrder: [],
        };
    }

    getWatch() {
        return {
            'SOSerNr':function (to, from) {
                //console.log(this.currentOrder);
                this.$store.dispatch('orderDetail',this.SOSerNr);
            },
            'refreshFlag':function (newValue,oldValue){
                //console.log(this.currentOrder);
                this.$store.dispatch('orderDetail',this.SOSerNr);
            }
        };
    }

    created() {
        return function () {
            //console.log("Order Detail",this.SOSerNr);
            if(this.SOSerNr)
                this.loadSalesOrder();
            else
                this.emitEvent('reset-order-view');
        };
    }

    mounted(){
        return function () {
            if (this.$route.query.action == "editOrder")
                this.viewMode = false;
        };
    }

    getComputed() {
        return {
            showItemImage: function () {
                return this.$store.getters.showItemImageInDetail;
            },
            discountItemCode:function (){
                return this.$store.getters.getDiscountItemCode;
            },
            shipItemCode:function (){
                return this.$store.getters.getShipItemCode;
            },
            showDiscount:function (){
                return this.$store.getters.getSetting.ShowDiscountInOrderDetail;
            },
            getDiscountCoupon:function (){
                if(this.currentOrder && this.currentOrder.fields.Items){
                    let items = __lodash__.cloneDeep(this.currentOrder.fields.Items);
                    let discountItem = __lodash__.find(items,['fields.ArtCode',"Desc"]);
                    if(discountItem){
                        if(this.$store.getters.getSetting.ShowPriceWithIVA)
                            return this.number_format(discountItem.fields.VATPrice,2);
                        else
                            return this.number_format(discountItem.fields.Price,2);
                    }
                }
                return null;
            },
            getShipCost:function (){
                if(this.currentOrder && this.currentOrder.fields.Items && this.shipItemCode){
                    let items = __lodash__.cloneDeep(this.currentOrder.fields.Items);
                    let shipCostItem = __lodash__.find(items,['fields.ArtCode',"ENVIO"]);
                    if(shipCostItem) {
                        if (this.$store.getters.getSetting.ShowPriceWithIVA)
                            return this.number_format(shipCostItem.fields.VATPrice, 2);
                        else
                            return this.number_format(shipCostItem.fields.Price, 2);
                    }
                }
                return null;
            },
            getOrderItems:function () {
                if (this.currentOrder) {
                    let self = this;
                    let items = __lodash__.cloneDeep(this.currentOrder.fields.Items);
                    if(this.$store.getters.getSetting.HideComponentsOfCompoundItems) {
                        let compoundItems = [];
                        for (let item of items) {
                            let rec = this.$store.getters.getItemByCode(item.fields.ArtCode);
                            if(rec && rec.ItemType === 3) {
                                compoundItems.push(item.fields.ArtCode);
                            } else if(rec && rec.ItemType !== 3 && !item.fields.Recipe)
                                compoundItems.push(item.fields.ArtCode);
                        }
                        let filteredItems = items.filter(x => compoundItems.includes(x.fields.ArtCode)); 
                        this.orderTemplate.fields.Items = filteredItems;
                        items = filteredItems;
                    }
                    __lodash__.remove(items,function (x) {
                        let itemCodeDiscount = self.$store.getters.getDiscountItemCode;
                        if(itemCodeDiscount && x.fields.ArtCode == itemCodeDiscount)
                            return true;
                        let itemCodeShipCost = self.$store.getters.getShipItemCode;
                        if(itemCodeDiscount && x.fields.ArtCode == itemCodeShipCost)
                            return true;
                        return false;
                    });
                    return items;
                }
                return null;
            },
            orderNeedLiberation:function (){
                return this.currentOrder.fields.OrderStatus == this.$store.getters.getSetting.PreAutorizationStatus;
            },
            currentOrder: function (){
                return this.$store.getters.getCurrentOrder;
            },
            currentOrderStatus: function (){
                return this.$store.getters.getSetting.OrderStatusList;
            },
            editable:function () {
                if(this.currentOrder.fields.Status || this.currentOrder.fields.Closed)
                    return false;
                if(this.isAdminPerson() || this.isUserOnlyModifier() || this.isUserLiberator() || this.currentOrder.fields.Contact == this.$store.state.session.fields.Code) {
                    return true;
                }
                return false;
            },
            liberator:function () {
                if(!this.viewMode)
                    return false;
                return this.isUserLiberator();
            },
            copier:function (){
                if(this.isUserOnlyModifier())
                    return false;
                if(this.currentOrder.fields.Contact == this.$store.state.session.fields.Code)
                    return true;
                return this.$store.getters.getUserIsAdmin;
            }
        };
    }

    getMethods() {
        return {
            isAdminPerson: this.isAdminPerson,
            isUserOnlyModifier:this.isUserOnlyModifier,
            isUserLiberator:this.isUserLiberator,
            goBack:this.goBack,
            itemQtyIncrement:this.itemQtyIncrement,
            itemQtyDecrement:this.itemQtyDecrement,
            deleteItemInOrder:this.deleteItemInOrder,
            copyOrder:this.copyOrder,
            updateSubTotals:this.updateSubTotals,
            changeMode:this.changeMode,
            saveOrder:this.saveOrder,
            cancelEdit:this.cancelEdit,
            orderAction:this.orderAction,
            getVATCode:this.getVATCode,
            loadSalesOrder:this.loadSalesOrder
        };
    }

    isAdminPerson() {
        return this.isAdmin;
    }

    isUserOnlyModifier() {
        return this.$store.state.onlyModifiedCodes.indexOf(this.currentOrder.fields.Contact) != -1 || this.$store.state.CenterOnlyModifiedCodes.indexOf(this.currentOrder.fields.CenterCost) != -1;
    }

    isUserLiberator(){
        //console.log("Liberatior Action ",this.$store.state.liberatorCodes,this.data.Contact);
        return this.$store.state.liberatorCodes.indexOf(this.currentOrder.fields.Contact) != -1 || this.$store.state.CenterLiberators.indexOf(this.currentOrder.fields.CenterCost) != -1;
    }

    goBack(){
        if(this.$route.query.order) {
            this.$router.push({path:'profile/Orders'});
        }
        else{
            if (this.goBackView)
                this.$store.dispatch('setOrderView', this.goBackView);
            this.emitEvent('reset-order-view');
        }
    }

    itemQtyIncrement(orderItem){
        orderItem.fields.Qty = parseInt(orderItem.fields.Qty) + 1;
        this.updateSubTotals(orderItem);
    }

    itemQtyDecrement(orderItem){
        if(parseInt(orderItem.fields.Qty) > 0)
            orderItem.fields.Qty = parseInt(orderItem.fields.Qty) - 1;
        if(orderItem.fields.Qty == 0){
            // eslint-disable-next-line no-alert
            let canDelete = confirm("Delete Item");
            if(canDelete)
                this.deleteItemInOrder(orderItem);
            else
                orderItem.fields.Qty = 1;
        }
        this.updateSubTotals(orderItem);
    }

    deleteItemInOrder(orderItem) {
        //console.log('Before',this.currentOrder.fields.Items);
        this.$store.dispatch('deleteItemCurrenOrder',orderItem);
        //console.log('After',this.currentOrder.fields.Items);
    }

    copyOrder(){
        //console.log('custom-actions: ', this.data, this.index,this.column);
        this.$store.dispatch('copyOrder',{orderId:this.currentOrder.fields.SerNr});
    }

    updateSubTotals(row){
        this.$store.dispatch("editOrderRow", row);
    }

    changeMode(){
        this.viewMode = !this.viewMode;
    }

    async saveOrder(){
        let result = await this.$store.dispatch('saveCurrentOrder');
        if(!result.success) {
            this.$store.commit('updateCurrentOrder', this.backup);
            this.refreshFlag = this.refreshFlag + 1;
        }
        this.changeMode();
    }

    cancelEdit(){
        this.loadSalesOrder();
        this.changeMode();
    }

    orderAction(action) {
        this.$store.dispatch('orderAction',{action:action,sernr:this.currentOrder.fields.SerNr,date:this.currentOrder.fields.TransDate});
    }

    getVATCode(item){
        let VATCode = this.$store.state.vatCodeMap.get(item.fields.VATCode);
        if(VATCode && VATCode.Percent)
            return VATCode.Percent;
        return 0;
    }

    loadSalesOrder(){
        //console.log('ComponetCreated')
        this.isOrderLoading = true;
        this.$store.dispatch('orderDetail',this.SOSerNr).then((orderObj)=>{
            if(orderObj.order) {
                this.backup = Object.assign({}, orderObj.order);
                this.isOrderLoading = false;
                this.libertorByOrder = orderObj.liberators;
            }
            else{
                this.$store.dispatch('showNotificacion',{title:"Information", message: `Order ${self.SOSerNr} not found`,type: 'warning'});
                this.$router.go(-1);
            }
        });
    }

    getTemplate() {
        return `<div id="container" class="order-detail" v-if="refreshFlag>0">
                <div class="container-fluid px-0 px-sm-3">
                    <div class="section-main">
                     <template v-if="isOrderLoading">
                          <div class="loading">
                              <i class="fas fa-spinner fa-spin fa-3x"></i>
                          </div>
                      </template>
                      <template v-else>
                        <div class="row">
                          <div class="col-12 px-1 px-md-3">
                            <div v-if="currentOrder">
                               <h5 class="title">
                                    <button class="btn secondary-btn" @click='goBack'><i class="icon fas fa-chevron-left"></i>{{tr('Back')}}</button>
                                    <span class="center mt-2 mt-sm-0 d-block d-sm-inline">{{tr('Sales Order')}} {{currentOrder.fields.SerNr}}</span>
                               </h5>

                               <div class="col-12 px-0 px-sm-3 mt-3 mb-4">
                                    <div class="stepwizard order-status">
                                        <div class="stepwizard-row">
                                            <div v-for="status in currentOrderStatus" class="stepwizard-step" :style="{'width': 100 / currentOrderStatus.length + '%'}">
                                                <p>{{status.fields.StateTitle}}</p>
                                                <button :class="{'btn disabled': true, 'active': status.fields.StateStatus == currentOrder.fields.OrderStatus}" :disabled="true">
                                                    <i :class="['fas',  (status.fields.StateIcon) ? 'fa-' + status.fields.StateIcon : 'fa-check']"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                               <div class="row order-customer-info">
                                  <div class="col-12 col-sm-6">
                                      <label>{{tr("Customer")}}:</label> <strong> {{currentOrder.fields.CustName}}</strong>
                                  </div>
                                  <div v-if="currentOrder.fields.ContactName" class="col-12 col-sm-6">
                                      <label>{{tr("Contact")}}:</label> <strong> {{currentOrder.fields.ContactName}}</strong>
                                  </div>
                                  <div class="col-12 col-sm-6">
                                      <label>{{tr("Delivery Address")}}:</label> <strong> {{currentOrder.fields.DelAddress}}</strong>
                                  </div>
                                  <div class="col-12 col-sm-6">
                                      <label>{{tr("TransDate")}}:</label> <strong> {{currentOrder.fields.TransDate}}</strong>
                                  </div>
                                  <div class="col-12 col-sm-6">
                                      <label>{{tr("Order Status")}}:</label> <strong> {{currentOrder.fields.OrderStatus}}</strong>
                                  </div>
                                  <div class="col-12 col-sm-6">
                                        <label>{{tr("Expiration Date")}}:</label> <strong> {{currentOrder.fields.DueDate}}</strong>
                                  </div>
                                  <div class="col-12" v-if="isAdmin && orderNeedLiberation && libertorByOrder.length > 0">
                                     <hr>
                                     <h5> Liberadores </h5>
                                     <ul>
                                     <template v-for="liberator of libertorByOrder">
                                        <li :key="liberator.fields.internalId"> {{liberator.fields.PersonName}} </li>
                                     </template>
                                     </ul>
                                  </div>
                               </div>
                               <div class="custom-actions-row">
                                    <div class="row custom-actions-default">
                                        <button key="copy-action" :title="tr('Copy')" @click.stop="copyOrder()" v-if="copier" class="btn">
                                            <i class="fa fa-copy"></i> {{tr("Copy")}}
                                        </button>
                                        <template v-if="viewMode">
                                            <button key="edit-action" :title="tr('Edit')" @click.stop="changeMode" v-if="editable" class="btn">
                                                <i class="fa fa-edit"></i> {{tr("Edit")}}
                                            </button>
                                        </template>
                                        <template v-else>
                                            <button key="save-action" :title="tr('Save')" @click.stop="saveOrder" v-if="editable" class="btn">
                                                <i class="fa fa-save"></i> {{tr("Save")}}
                                            </button>
                                            <button key="cancel-action" :title="tr('Cancel')" @click.stop="cancelEdit" v-if="editable" class="btn">
                                                <i class="fa fa-ban"></i> {{tr("Cancel")}}
                                            </button>
                                        </template>
                                        <template v-if="orderNeedLiberation">
                                            <button key="approved-action" :title="tr('Approved')" @click.stop="orderAction('liberate', currentOrder)" v-if="liberator || isAdmin" class="btn">
                                                <i class="fa fa-check"></i> {{tr("Approved")}}
                                            </button>
                                            <button key="reject-action" :title="tr('Reject')" @click.stop="orderAction('reject', currentOrder)" v-if="liberator || isAdmin" class="btn">
                                                <i class="fa fa-minus"></i> {{tr('Reject')}}
                                            </button>
                                        </template>
                                    </div>
                               </div>
                               <div class="table-responsive">
                                    <table v-if="viewMode" class="table table-striped">
                                         <thead>
                                            <tr>
                                                <th v-if="showItemImage"></th>
                                                <th>{{tr('Code')}}</th>
                                                <th width="35%">{{tr('Products')}}</th>
                                                <th>{{tr('UM')}}</th>
                                                <th>{{tr('Qty')}}</th>
                                                <th>{{tr('Price')}}</th>
                                                <template v-if="showDiscount">
                                                    <th>{{tr('Discount')}} %</th>
                                                </template>
                                                <th>{{tr('VAT')}}</th>
                                                <th class="text-right">{{tr('Total')}} {{tr('VAT INC')}}</th>
                                            </tr>
                                         </thead>
                                         <tbody>
                                            <tr v-for="item of getOrderItems">
                                                <td v-if="showItemImage" width="5%"><itemSliderComponent :item="$store.getters.getItemByCode(item.fields.ArtCode)" :container="false" :showCarusel="false"></itemSliderComponent></td>
                                                <td>{{item.fields.ArtCode}}</td>
                                                <td width="35%">{{item.fields.Name}}</td>
                                                <td>{{item.fields.Unit}}</td>
                                                <td>{{item.fields.Qty}} </td>
                                                <td>$ {{formatFloat(item.fields.VATPrice)}}</td>
                                                <template v-if="showDiscount">
                                                    <td v-if="item.fields.Discount">{{formatFloat(item.fields.Discount)}} %</td>
                                                    <td class="text-center" v-else >-</td>
                                                </template>
                                                <td>{{getVATCode(item)}} %</td>
                                                <td class="text-right">$ {{formatFloat(item.fields.RowTotal)}}</td>
                                            </tr>
                                         </tbody>
                                         <tfoot class="table-info">
                                           <tr v-if="getDiscountCoupon">
                                                <td v-if="showItemImage"></td>
                                                <td colspan="8" class="text-left text-md-right">
                                                  <template >
                                                    <strong>{{tr("Discount")}}: $ {{getDiscountCoupon}} </strong>
                                                  </template>
                                                </td>
                                            </tr>
                                             <tr v-if="getShipCost!=null">
                                                <td v-if="showItemImage"></td>
                                                <td colspan="8" class="text-left text-md-right">
                                                  <template>
                                                    <strong>{{tr("Ship Cost")}}: $ {{getShipCost}}</strong> 
                                                  </template>
                                                </td>
                                            </tr>
                                           <tr>
                                             <td v-if="showItemImage"></td>
                                             <td></td>
                                             <td></td>
                                             <td colspan="2" class="text-right">
                                                <strong>SubTotal:</strong>
                                                {{formatFloat(currentOrder.fields.SubTotal)}}
                                             </td>
                                             <td colspan="2" class="text-right">
                                                <strong>{{tr('Taxes')}}:</strong>
                                                {{formatFloat(currentOrder.fields.TotalTax)}}
                                             </td>
                                             <td colspan="2" class="text-right">
                                                <strong>Total</strong>
                                                {{formatFloat(currentOrder.fields.Total)}}
                                             </td>
                                           </tr>
                                         </tfoot>
                                    </table>
                                    <table v-if="!viewMode" class="table table-striped">
                                         <thead>
                                            <tr>
                                                <th v-if="showItemImage"></th>
                                                <th>{{tr('Code')}}</th>
                                                <th width="35%">{{tr('Products')}}</th>
                                                <th>{{tr('UM')}}</th>
                                                <th>{{tr('Qty')}}</th>
                                                <th>{{tr('Price')}} {{tr('VAT INC')}}</th>
                                                <th>{{tr('Total')}} {{tr('VAT INC')}}</th>
                                                <th>{{tr('Delete')}}</th>
                                            </tr>
                                         </thead>
                                         <tbody>
                                            <tr v-for="item of getOrderItems">
                                                <td v-if="showItemImage" width="5%"><itemSliderComponent :item="$store.getters.getItemByCode(item.fields.ArtCode)" :container="false" :showCarusel="false"></itemSliderComponent></td>
                                                <td>{{item.fields.ArtCode}}</td>
                                                <td width="35%">{{item.fields.Name}}</td>
                                                <td>{{item.fields.Unit}}</td>
                                                <td class="row">
                                                    <span  class="col-2" @click="itemQtyIncrement(item)"><i class="fas fa-angle-up waves-effect" ></i></span>
                                                    <input class="col-8" type="text" min="1"  v-model="item.fields.Qty" placeholder="0" @change="updateSubTotals(item)">
                                                    <span  class="col-2"@click="itemQtyDecrement(item)"><i class="fas fa-angle-down waves-effect" ></i></span>
                                                </td>
                                                <td>$ {{formatFloat(item.fields.VATPrice)}}</td>
                                                <td>$ {{formatFloat(item.fields.RowTotal)}}</td>
                                                <td><span @click="deleteItemInOrder(item)" ><i class="far fa-trash-alt" ></i></span></td>
                                            </tr>
                                         </tbody>
                                         <tfoot class="table-info">
                                            <td v-if="showItemImage"></td>
                                             <td class="text-right"><strong>SubTotal:</strong></td>
                                             <td>{{formatFloat(currentOrder.fields.SubTotal)}}</td>
                                             <td>&nbsp;</td>
                                             <td>&nbsp;</td>
                                             <td class="text-right"><strong>{{tr('Taxes')}}:</strong></td>
                                             <td>{{formatFloat(currentOrder.fields.TotalTax)}}</td>
                                             <td class="text-right"><strong>Total</strong></td>
                                             <td>{{formatFloat(currentOrder.fields.Total)}}</td>
                                         </tfoot>
                                    </table>
                                </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                </div>
            </div>`;
    }
}

orderDetailComponent.registerComponent();
